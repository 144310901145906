import React, { useState } from 'react'
import {FaBars, FaTimes, FaGithub, FaLinkedin, FaFacebook} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import Logo from '../assets/dc logo (1).png'
import { Link } from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300'>
        <div>
            <img src={Logo} alt="Logo image" style={{width: '80px'}}/>
        </div>

        {/* menu */}

            <ul className='hidden md:flex'>
                <li>
                    <Link to="home"  smooth={true}  duration={500}>
                    Home
                    </Link>
                </li>
                <li>
                    <Link to="about"  smooth={true}  duration={500}>
                    About
                    </Link>
                </li>
                <li>
                    <Link to="skills"  smooth={true}  duration={500}>
                    Skills
                    </Link>
                </li>
                <li>
                    <Link to="work"  smooth={true}  duration={500}>
                    Work
                    </Link>
                </li>
                <li>
                    <Link to="contact"  smooth={true}  duration={500}>
                    Contact
                    </Link>
                </li>
            </ul>

        {/* hamburger */}
            <div onClick={handleClick} className='md:hidden z-10'>
                {!nav ? <FaBars /> : <FaTimes />}
            </div>

            {/* Mobile menu */}
            <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="home"  smooth={true}  duration={500}>
                    Home
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="about"  smooth={true}  duration={500}>
                    About
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="skills"  smooth={true}  duration={500}>
                    Skills
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="work"  smooth={true}  duration={500}>
                    Work
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="contact"  smooth={true}  duration={500}>
                    Contact
                    </Link>
                </li>
            </ul>


            {/* Social icons */}
            <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                        <a className='flex justify-between items-center w-full text-gray-300'
                        href="https://www.linkedin.com/in/diego-callegari-a60496238/" target='blank'>
                            Linkedin <FaLinkedin size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                        <a className='flex justify-between items-center w-full text-gray-300'
                        href="https://github.com/diegocallegari96" target='blank'>
                            Github <FaGithub size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#c71610]'>
                        <a className='flex justify-between items-center w-full text-gray-300'
                        href="mailto:diegoocallegari@gmail.com?subject=Inquiry from Portfolio&body=Hello,">
                            Email <HiOutlineMail size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#f2a60c]'>
                        <a className='flex justify-between items-center w-full text-gray-300'
                        href="https://drive.usercontent.google.com/u/0/uc?id=1bY34nU6T1oTGMAx7Xs-BRkrJz_4rv9nz&export=download">
                            Resume <BsFillPersonLinesFill size={30} />
                        </a>
                    </li>
                    
                </ul>
            </div>
    </div>
  )
}

export default Navbar